import { Action, AnyAction, combineReducers, Dispatch } from 'redux';
import { all, fork } from 'redux-saga/effects';

import * as Export from './app/Export/reducer';
import * as Filter from './app/Filter/reducer';
import * as Modal from './app/Modal/reducer';
import * as NationalSearch from './app/NationalSearch/reducer';
import * as PartnerSearch from './app/PartnerSearch/reducer';
import * as Projects from './app/Projects/reducer';
import * as Query from './app/Query/reducer';
import * as Saved from './app/Saved/reducer';
import * as Search from './app/Search/reducer';
import * as Settings from './app/Settings/reducer';
import { IUser } from './app/User/actions';
import * as User from './app/User/reducer';
import * as Funding from './app/Fundings/reducers';
// import * as AiSearch from './app/AiSearch/reducer';

import exportSaga from './app/Export/sagas';

import nationalSearchSaga from './app/NationalSearch/sagas';
import partnerSearchSaga from './app/PartnerSearch/sagas';
import projectsSata from './app/Projects/sagas';
import querySaga from './app/Query/sagas';
import savedSaga from './app/Saved/sagas';
import searchSaga from './app/Search/sagas';
import settingsSaga from './app/Settings/sagas';
import fundingSaga from './app/Fundings/sagas';
// import aiSearchSaga from './app/AiSearch/sagas';

export interface IState {
  partners: PartnerSearch.IPartnerSearchState;
  projects: Projects.IProjectsState;
  searchResults: Search.ISearchState;
  nationalSearchResults: NationalSearch.INationalSearchState;
  export: Export.IExportState;
  user: auth0.Auth0UserProfile & IUser;
  modal: Modal.IModalState;
  filter: Filter.IFilterState;
  query: Query.IQueryState;
  saved: Saved.ISavedState;
  settings: Settings.ISettingsState;
  funding: Funding.IFundingState;
}

export const initialState: IState = {
  partners: PartnerSearch.initialState,
  projects: Projects.initialState,
  export: Export.initialState,
  searchResults: Search.initialState,
  nationalSearchResults: NationalSearch.initialState,
  user: User.initialState,
  modal: Modal.initialState,
  filter: Filter.initialState,
  query: Query.initialState,
  saved: Saved.initialState,
  settings: Settings.initialState,
  funding: Funding.initialState
};

export interface IConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}

export const rootReducer = combineReducers<IState>({
  export: Export.exportReducer,
  searchResults: Search.searchReducer,
  nationalSearchResults: NationalSearch.nationalSearchReducer,
  partners: PartnerSearch.partnerSearchReducer,
  projects: Projects.projectsReducer,
  user: User.userReducer,
  modal: Modal.modalReducer,
  filter: Filter.filterReducer,
  query: Query.queryReducer,
  saved: Saved.savedReducer,
  settings: Settings.settingsReducer,
  funding: Funding.fundingReducer
});

export function* rootSaga() {
  yield all([
    fork(searchSaga),
    fork(nationalSearchSaga),
    fork(exportSaga),
    fork(partnerSearchSaga),
    fork(projectsSata),
    fork(querySaga),
    fork(savedSaga),
    fork(settingsSaga),
    fork(fundingSaga)
  ]);
}
